@import '@/assets/styles/global.scss';

.Switcher {
  position: relative;
  $self: &;

  &-input {
    position: absolute;
    z-index: -9999;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + #{$self} {
      &-label {
        &::before {
          background-color: var(--primary-color);
        }

        &::after {
          transform: translateX(24px);
        }
      }
    }
  }

  &-label {
    display: inline-flex;
    cursor: pointer;

    &::before {
      content: '';
      display: flex;
      width: 48px;
      padding: 4px 4px 4px 28px;
      border-radius: 14px;
      height: 24px;
      background-color: var(--gray-color);
      @extend %transition;
    }

    &:after {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 100px;
      background-color: #fff;
      z-index: 1;
      position: absolute;
      left: 4px;
      top: 4px;
      @extend %transition;
    }

    &_disabled {
      pointer-events: none;
    }
  }
}
