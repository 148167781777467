@import '@/assets/styles/global.scss';

.SepaTransfer {
  &-list {
    margin-bottom: 18px;
  }

  &-btns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include mobile {
      gap: 10px;
    }

    @include xmobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-btn {
    width: 100%;
  }

  &-print {
    position: fixed;
    z-index: -9999;
    opacity: 0;
    display: none;
  }
}
