@import '@/assets/styles/global.scss';

.Checkbox {
  position: relative;
  $self: &;

  &-input {
    position: absolute;
    z-index: -9999;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + #{$self} {
      &-label {
        &:after {
          opacity: 1;
        }

        // &::before {
        //   border: 1px solid var(--primary-color);
        // }

        &_dark {
          &::before {
            border: 1px solid var(--text-gray-small);
          }
        }
      }
    }
  }

  &-label {
    display: inline-flex;
    cursor: pointer;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 4px;
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 4px;
      top: 4px;
      opacity: 0;
      border-radius: 2px;
    }

    &_blue {
      &::before {
        background-color: var(--white-color);
        border: 1px solid var(--gray-color-100);
      }

      &:after {
        background-color: var(--primary-color);
      }
    }

    &_dark {
      &::before {
        background-color: var(--white-color);
        border: 1px solid var(--gray-color-100);
      }

      &:after {
        background-color: var(--primary-color);
      }
    }

    &_disabled {
      pointer-events: none;
    }

    &_error {
      &::before {
        border: 1px solid var(--red-color);
      }
    }
  }

  &-text {
    width: calc(100% - 28px);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--bg-header-color);
    margin-left: 10px;

    a {
      color: var(--primary-color);
      @extend %transition;

      &:hover {
        color: var(--bg-header-color);
      }
    }
  }
}
