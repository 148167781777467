@import '@/assets/styles/global.scss';

.Currency {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  border-top: 1px solid var(--gray-color-100);

  @include tablet {
    flex-wrap: wrap;
  }

  &-first {
    display: flex;
    align-items: center;
    max-width: 40%;
    width: 100%;
  }

  &-text {
    margin-left: 50px;

    @include tablet {
      margin-left: 15px;
    }
  }

  &-small {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: var(--black-color);
  }

  &-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--gray-color-400);
  }

  &-icon {
    font-size: 18px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    // filter: drop-shadow(0px 5px 5px rgba(#5244f3, 0.3));
    box-shadow: 0 5px 5px rgba(var(--button-primary-bg), 0.3);
  }

  &-graph {
    max-width: 25%;
    width: 100%;
  }

  &-value {
    margin-right: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--green-color);

    &_down {
      color: var(--red-color);
    }
  }

  &-actions {
    max-width: 35%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    display: flex;
    gap: 10px;

    @include tablet {
      max-width: 100%;
      margin-top: 10px;
    }
  }
}
